.headerMainGrid { 
    margin-top: 0px;
    display: inline-flex;
}

.logoImage { 
    display: flex;
    width: 54px;
    align-items: baseline;
}

.role { 
    font-family: 'Alegreya';
    font-size: 16pt;
}