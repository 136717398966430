.resourceCard { 
    display: flex;
    position: relative;
}

.resourceImage {
    position: relative;
    align-items: center;
    display: flex;
    max-width: 100%; 
    max-height: 100%;
    height: 300px;
    width: 300px;
}

.resourceItem { 
    align-items: center;
    flex-direction: column;
    display: flex;
    padding: 4px 0 4px 15px;
    position: relative;
}

@media only screen and (min-width: 768px) {
    .resourceItem {
      width: 44%;
    }
    .resourceImage {
      max-width: 708px;
    }
  }

@media only screen and (min-width: 960px ) {
    .resourceItem { 
        width: 44%;
    }
}